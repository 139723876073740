export default class Modale {
  constructor(element) {
    this.element = element;
    this.options = {
      backgroundColor: '#FFF',
      color: '#000',
    };
    this.init();
  }

  init() {
    this.img = this.element.querySelector('img');
    this.img.addEventListener('click', this.showModale.bind(this));
  }

  setOptions() {
    if ('color' in this.element.dataset) {
      this.options.color = this.element.dataset.color;
    }
    if ('background' in this.element.dataset) {
      this.options.backgroundColor = this.element.dataset.background;
    }
  }

  showModale() {
    this.setOptions();

    this.modale = this.element.querySelector('.modale');
    this.modaleImg = this.modale.querySelector('img');
    this.modaleBtn = this.modale.querySelector('.close');

    this.modaleImg.setAttribute('src', this.img.getAttribute('src'));
    this.modaleImg.setAttribute('alt', this.img.getAttribute('alt'));
    this.modaleImg.style.background = `${this.options.backgroundColor}`;
    this.modale.style.color = `${this.options.color}`;
    this.modaleBtn.style.color = `${this.options.color}`;

    this.modale.classList.add('is-active');
    this.modaleBtn.addEventListener('click', this.closeModale.bind(this));
  }

  closeModale() {
    this.modale.classList.remove('is-active');
  }
}

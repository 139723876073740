import Swiper from 'swiper/bundle';

export default class Carousel {
  constructor(element) {
    this.element = element;
    this.variant = this.element.dataset.variant;
    this.type = this.element.dataset.type;
    this.options = {
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: {
        el: this.element.querySelector('.swiper-pagination'),
        type: 'bullets',
      },
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev'),
      },
    };
    this.init();
  }

  init() {
    this.setOptions();
    if (this.type == 'navExt') {
      const swiper = new Swiper(
        this.element.querySelector('.swiper'),
        this.options
      );
    } else {
      const swiper = new Swiper(this.element, this.options);
    }
  }

  setOptions() {
    if (this.variant == 'diapo') {
      this.options.breakpoints = {
        768: {
          slidesPerView: 1.5,
          loop: true,
          spaceBetween: 32,
        },
      };
      this.options.autoplay = {
        delay: 1000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      };
    }
    if (this.variant == 'split') {
      this.options.breakpoints = {
        768: {
          slidesPerView: 2.5,
        },
      };
    }
    if ('autoplay' in this.element.dataset) {
      this.autoplayDelay = this.element.dataset.autoplay;
      this.options.autoplay = {
        delay: this.autoplayDelay,
        pauseOnMouseEnter: false,
        disableOnInteraction: false,
      };
    }
    if ('loop' in this.element.dataset) {
      this.options.loop = true;
    }
    if ('gap' in this.element.dataset) {
      this.gap = this.element.dataset.gap;
      this.options.spaceBetween = parseInt(this.gap);
    }
    if ('slides' in this.element.dataset) {
      this.options.slidesPerView = parseFloat(this.element.dataset.slides);
    }
    if (this.variant == 'fade') {
      this.options.effect = 'fade';
      this.options.crossFade = true;
    }
    if (this.variant == 'coverflow') {
      this.options.effect = 'coverflow';
    }

    if (this.variant == 'avis') {
      this.options.slidesPerView = 1;

      this.options.breakpoints = {
        750: {
          slidesPerView: 2,
          spaceBetween: 64,
        },
      };
      // this.options.autoplay = {
      //   delay: 2000,
      //   pauseOnMouseEnter: true,
      //   disableOnInteraction: false,
      // };
    }
    if (this.variant == 'thumbs') {
      // this.options.free
    }
    if ('thumbs' in this.element.dataset) {
      const thumbsSwiperContainer = document.querySelector('.swiper-thumbs');
      const thumbs = new Swiper(thumbsSwiperContainer, {
        spaceBetween: 12,
        slidesPerView: 4,
      });
      this.options.thumbs = {
        swiper: thumbs,
      };
    }
  }
}
